import React, { memo, useState } from 'react';
import isEqual from 'lodash.isequal';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { BrandBadge, Icon, InfoBadge, NeutralBadge, RoundIconButton } from '@hz-design-system/web-ui';
import { ImageSize } from '@ecg-marktplaats/media-util-js-lib';
import { ListingAttribute, ListingDetailsButton, ListingSellerName } from '@/client/components/Listings/atoms';
import { imageFormatter, imageSizeFormatter, opvalStickerFormatter } from '@/client/utils/formatters';
import {
  PRICE_TYPE_ATTRIBUTE_VALUE_KEY_FR,
  PRICE_TYPE_ATTRIBUTE_VALUE_KEY_NL,
} from '@/constants/attributes/nonStandards';
import formatAttributeBasedOnKey from '@/client/utils/formatters/formatBasedOnKey';
import ListingListViewFigure from '@/client/components/Listings/molecules/ListingListViewFigure';
import ListingVipLink from '@/client/components/Listings/atoms/ListingVipLink';
import SellerExternalWebsite from '@/client/components/Listings/molecules/SellerExternalWebsite';
import PRICE_TYPE from '@/client/constants/priceTypes';

import { Attribute } from '@/client/types/Listings';
import { TImage } from '@/client/types/Images';

const SPACE_SEPARATOR = ' ';
const MILEAGE_ATTRIBUTE_KEY = 'mileage';
const CAR_PASS_ATTRIBUTE_KEY = 'carPassUrl';
const OPTIONS_ATTRIBUTE_KEY = 'options';
const FUEL_ATTRIBUTE_KEY = 'fuel';
const CAR_ATTRIBUTES = ['constructionYear', MILEAGE_ATTRIBUTE_KEY, FUEL_ATTRIBUTE_KEY, 'transmission', 'body'];
const CAR_EV_ATTRIBUTES = ['constructionYear', MILEAGE_ATTRIBUTE_KEY, FUEL_ATTRIBUTE_KEY, 'range', 'body'];
const DISPLAYED_OPTIONS_COUNT = 3;
const transformCamelToSnakeCase = (key) => key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

type TListingListViewContentCars = {
  attributes: Attribute[];
  categories?: TCategories;
  categorySpecificDescription?: string;
  correlationId?: string;
  date: string;
  decodingType?: 'sync' | 'async' | 'auto';
  description: string;
  extendedAttributes: Attribute[];
  isFavorite?: boolean;
  loadingType?: 'eager' | 'lazy';
  location: { label: string; distance?: string };
  napAvailable?: boolean;
  onFavorite: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  opvalStickerText?: string;
  pageLocation?: string;
  pictures?: TImage[];
  price: string;
  priceType?: string;
  priorityProduct?: string;
  sellerInformation?: TSellerInformation;
  shouldDisplayExtendedContent?: boolean;
  subtitle?: string;
  thinContent?: boolean;
  title: string;
  trackingData?: string;
  trustIndicators?: { value?: string; key: string }[];
  urgencyFeatureActive?: boolean;
  vipUrl: string;
  isBottomBlock?: boolean;
};
type TCarAttributes = { key: string; value: string; unit?: string; values?: string[]; icon: string }[];

const ListingListViewContentCars = ({
  attributes = [],
  categories,
  categorySpecificDescription,
  correlationId,
  date,
  decodingType,
  description,
  extendedAttributes = [],
  isFavorite = false,
  loadingType,
  location,
  napAvailable = false,
  onFavorite,
  opvalStickerText,
  pageLocation,
  pictures,
  price,
  priceType,
  priorityProduct = '',
  sellerInformation,
  shouldDisplayExtendedContent,
  subtitle,
  thinContent,
  title,
  trackingData,
  trustIndicators = [],
  urgencyFeatureActive = false,
  vipUrl,
}: TListingListViewContentCars) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { t } = useI18nContext();
  let sellerLogoUrl;
  if (sellerInformation?.sellerLogoUrl) {
    sellerLogoUrl = imageSizeFormatter({
      imageUrlFromApi: sellerInformation.sellerLogoUrl,
      customSize: ImageSize.Medium,
    });
  }

  const options = attributes.find((x) => x.key === OPTIONS_ATTRIBUTE_KEY);
  let optionsValue = '';
  if (options?.values) {
    const remainingOptionsCount =
      options.values.length > DISPLAYED_OPTIONS_COUNT
        ? `, + ${options.values.length - DISPLAYED_OPTIONS_COUNT} ${t('listings.attributes.options')}`
        : '';
    optionsValue = `${options.values.slice(0, DISPLAYED_OPTIONS_COUNT).join(', ')}${remainingOptionsCount}`;
  }

  const carPassUrl = attributes.find((x) => x.key === CAR_PASS_ATTRIBUTE_KEY);

  const currencyPriceTypes = [PRICE_TYPE.MIN_BID, PRICE_TYPE.FIXED];
  const isCurrency = currencyPriceTypes.includes(priceType as PRICE_TYPE);
  const isPricePerMonth =
    isCurrency &&
    attributes.find(
      (attr) => attr.value === PRICE_TYPE_ATTRIBUTE_VALUE_KEY_NL || attr.value === PRICE_TYPE_ATTRIBUTE_VALUE_KEY_FR,
    );

  const carAttributes: TCarAttributes = [];
  const isEvCar = attributes?.find(
    ({ key, value }) => key === 'fuel' && (value === 'Elektrisch' || value === 'Électrique'),
  );
  const carAttributeKeys = isEvCar ? CAR_EV_ATTRIBUTES : CAR_ATTRIBUTES;
  carAttributeKeys.forEach((key) => {
    const listingAttribute = attributes?.find((x) => x.key === key);
    if (listingAttribute) {
      carAttributes.push({
        ...listingAttribute,
        icon: `car_${transformCamelToSnakeCase(key)}`,
      });
    }
  });
  return (
    <>
      <ListingVipLink
        vipUrl={vipUrl}
        trackingData={trackingData}
        correlationId={correlationId}
        pageLocation={pageLocation}
        thinContent={thinContent}
        className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-coverLink' })}
      >
        <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-image' })}>
          <ListingListViewFigure
            images={imageFormatter(pictures ?? [], title, categories, extendedAttributes)}
            loadingType={loadingType}
            decodingType={decodingType}
          />
          {(opvalStickerText || urgencyFeatureActive) && (
            <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-badges' })}>
              {opvalStickerText && (
                <BrandBadge className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-badge' })}>
                  {opvalStickerFormatter(opvalStickerText)}
                </BrandBadge>
              )}
              {urgencyFeatureActive && (
                <InfoBadge className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-badge' })}>
                  <Trans tagName="span" i18nKey="listings.moet-nu-weg" />
                </InfoBadge>
              )}
            </div>
          )}
        </div>

        <div
          className={getSuitCSSClasses({
            namespace: 'hz',
            name: 'Listing-group',
            modifiers: sellerLogoUrl ? ['with-seller-logo'] : [],
          })}
        >
          <div
            className={`${getSuitCSSClasses({ namespace: 'hz', name: 'Listing-headings' })} u-colorTextSecondary withFavoriting`}
          >
            <h3
              className={`${getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-title',
              })} hz-Text hz-Text--bodyLargeStrong withFavoriting`}
            >
              {title}
              {subtitle && <span className="hz-Text hz-Text--bodyLarge u-colorTextSecondary">{` ${subtitle}`}</span>}
            </h3>

            <div className={`${getSuitCSSClasses({ namespace: 'hz', name: 'Listing-price-extended-details' })}`}>
              {isPricePerMonth && <span>{`${t('listings.leaseprice.prefix')}`}&nbsp;</span>}
              <span className="hz-Title hz-Title--title4">{price}</span>
              {isPricePerMonth && <span>&nbsp;{`${t('listings.leaseprice.suffix')}`}</span>}
              {shouldDisplayExtendedContent && (
                <ListingDetailsButton isDetailsOpen={isDetailsOpen} setIsDetailsOpen={setIsDetailsOpen} />
              )}
            </div>
          </div>
          <p
            className={getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-description',
              custom: 'hz-text-paragraph',
            })}
          >
            {description}
          </p>

          {Boolean(carAttributes.length) && (
            <div
              className={`${getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-attributes',
              })} hz-Text hz-Text--bodyRegularStrong`}
            >
              {carAttributes.map(({ key, value, unit, icon }) => (
                <ListingAttribute type="default" key={key}>
                  <Icon name={icon} />
                  {formatAttributeBasedOnKey(key, value, undefined, undefined)}
                  {SPACE_SEPARATOR}
                  {unit && unit}
                  {key === MILEAGE_ATTRIBUTE_KEY && napAvailable && (
                    <span title={t('listings.nap')}>
                      <Icon name="checkmark" color="success" size="m" />
                    </span>
                  )}
                  {key === MILEAGE_ATTRIBUTE_KEY && carPassUrl && (
                    <span title={t('listings.carPass')}>
                      <Icon name="checkmark" color="signal-link-default" size="m" />
                    </span>
                  )}
                </ListingAttribute>
              ))}
            </div>
          )}

          {optionsValue && (
            <div
              className={`${getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-attribute-options',
              })} hz-Text hz-Text--regular u-colorTextSecondary`}
            >
              {optionsValue}
            </div>
          )}
          {shouldDisplayExtendedContent && (
            <details
              className={
                isDetailsOpen
                  ? getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Listing-extended-content',
                      modifiers: ['open'],
                    })
                  : getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Listing-extended-content',
                    })
              }
              open
            >
              <summary></summary>
              <div
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-extended-attributes',
                })}
              >
                {options?.values?.slice(0, DISPLAYED_OPTIONS_COUNT)?.map((option, key) => (
                  <ListingAttribute key={key} type="extended">
                    {option}
                  </ListingAttribute>
                ))}
              </div>
              {
                <p
                  className={getSuitCSSClasses({
                    namespace: 'hz',
                    name: 'Listing-description',
                  })}
                >
                  {categorySpecificDescription}
                </p>
              }
            </details>
          )}

          {Boolean(trustIndicators?.length) && (
            <div
              className={`${getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-highlights',
              })} hz-Text hz-Text--bodyRegularStrong u-colorTextSecondary`}
            >
              {trustIndicators?.map((trustIndicator, key) => (
                <NeutralBadge className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-highlight' })} key={key}>
                  <Trans tagName="span" i18nKey={`trustIndicator.${trustIndicator.key}`} />
                </NeutralBadge>
              ))}
            </div>
          )}

          <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-action' })}>
            <RoundIconButton.Tertiary size="small" name={isFavorite ? 'heart_filled' : 'heart'} onClick={onFavorite} />
          </div>
        </div>
      </ListingVipLink>

      <div
        className={getSuitCSSClasses({
          namespace: 'hz',
          name: 'Listing-footer',
        })}
      >
        {sellerLogoUrl && (
          <img
            src={sellerLogoUrl}
            style={{ backgroundImage: `url(${sellerLogoUrl})` }}
            className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-image' })}
            alt={t('seller.logo.alt')}
          />
        )}
        <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller' })}>
          {sellerInformation && (
            <ListingSellerName link={sellerInformation.soiUrl} isVerifiedSeller={false} as="hz-Listing-sellerName">
              {sellerInformation.sellerName}
            </ListingSellerName>
          )}
          <div
            className={`${getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-sellerLocation',
            })} u-colorTextSecondary`}
          >
            {location.label}
            {location.distance ? (
              <span
                className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-sellerDistance' })}
              >{`${location.distance}`}</span>
            ) : (
              ''
            )}
          </div>
          {sellerInformation?.showWebsiteUrl && sellerInformation?.sellerWebsiteUrl && (
            <SellerExternalWebsite sellerWebsiteUrl={sellerInformation.sellerWebsiteUrl} />
          )}
        </div>

        <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-meta' })}>
          {priorityProduct !== 'NONE' && (
            <div
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-priority',
                modifiers: ['all-devices'],
              })}
            >
              <Trans tagName="span" i18nKey={`priorityProduct.${priorityProduct}`} />
            </div>
          )}
          <div
            className={`${getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-listingDate',
            })} u-colorTextSecondary`}
          >
            {date}
          </div>
        </div>
      </div>
    </>
  );
};

// todo: find which properties are really needs to be checked for preventing rerendering
function arePropsEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(ListingListViewContentCars, arePropsEqual);
