import { ImageSize, formatImageUrl, getURlWithoutJPG } from '@ecg-marktplaats/media-util-js-lib';
import { IMAGE_ALT_TEXT_LENGTH_LIMIT } from '@/client/constants/listings';
import { TImage } from '@/client/types/Images';

interface ExtendedAttribute {
  value?: string;
}

const generateImageAlt = (title: string, categories: TCategories, extendedAttributes: ExtendedAttribute[]): string => {
  const l1Category = categories?.l1Category?.fullName;
  const l2Category = categories?.l2Category?.fullName;
  let formattedAltText = `${title}`;

  if (l1Category) {
    formattedAltText += `, ${l1Category}`;
  }
  if (l2Category) {
    formattedAltText += `, ${l2Category}`;
  }

  extendedAttributes?.forEach((attr) => {
    if (attr.value && formattedAltText.length < IMAGE_ALT_TEXT_LENGTH_LIMIT) {
      formattedAltText += `, ${attr.value}`;
    }
  });

  return formattedAltText;
};

const generateImageObject = (
  image: TImage,
  title: string,
  categories: TCategories,
  extendedAttributes: ExtendedAttribute[],
) => {
  const altText = generateImageAlt(title, categories, extendedAttributes);
  const sizeMap = !Array.isArray(image.sizes) ? image.sizes : undefined;

  return {
    alt: altText,
    title: altText,
    imageSource: formatImageUrl(image.url, ImageSize.Medium, sizeMap),
    url: image.url ? getURlWithoutJPG(image.url) : undefined,
    sizes: image.sizes,
  };
};

const imageFormatter = (
  images: TImage[],
  title: string = '',
  categories: TCategories = {},
  extendedAttributes: ExtendedAttribute[] = [],
) => (images ? images.map((image) => generateImageObject(image, title, categories, extendedAttributes)) : []);

export default imageFormatter;
