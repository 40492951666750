import React, { useState, useEffect, useRef, useCallback, Suspense } from 'react';
import dynamic from 'next/dynamic';
import { isIntersectionObserverSupported } from '@/client/utils/browser/isInViewport';
import { ADSENSE_TOP_CONTAINER_ID } from '@/constants/adsense';
import isSsr from '@/client/utils/isSsr';

const DynamicAdsenseForSearch = dynamic(() => import('@/client/components/AdsenseForSearch'), {
  ssr: false,
});

interface AdsenseContainerProps {
  query?: string;
  pageType: 'LRP' | 'ZRP';
  searchCategoryName?: string;
  channel: string;
  currentPage: number;
}

const useIntersectionObserver = (
  ref: React.RefObject<HTMLElement | null>,
  rootMargin: string,
  callback: () => void,
) => {
  useEffect(() => {
    if (isSsr()) return;

    if (!isIntersectionObserverSupported.get()) {
      callback();
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback();
          observer.disconnect();
        }
      },
      { rootMargin },
    );

    const current = ref.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, rootMargin, callback]);
};

const AdsenseContainer: React.FC<AdsenseContainerProps> = ({
  query = '',
  pageType,
  searchCategoryName = '',
  channel,
  currentPage,
}) => {
  const [isIntersected, setIsIntersected] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isUserConsentPresent = useRef<boolean>(false);

  const trackGAEvent = useCallback((label: string) => {
    if (typeof window !== 'undefined' && (window as any).ecGa) {
      (window as any).ecGa('trackEvent', {
        eventAction: 'LazyLoadAdsense',
        eventLabel: label,
      });
    }
  }, []);

  const handleIntersection = useCallback(() => {
    setIsIntersected(true);
    trackGAEvent('intersected');
  }, [trackGAEvent]);

  useIntersectionObserver(containerRef, '0px 0px 1500px 0px', handleIntersection);

  useEffect(() => {
    if (isIntersected) {
      const script = document.createElement('script');
      script.src = '//www.google.com/adsense/search/ads.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, [isIntersected]);

  useEffect(() => {
    if (!isSsr()) {
      const consentState = (window as any)?._sp_lib?.getConsentState(document.cookie);
      isUserConsentPresent.current = !!consentState?.Google;
    }
  }, []);

  useEffect(() => {
    if (!isSsr() && !isIntersectionObserverSupported.get()) {
      setIsIntersected(true);
      trackGAEvent('notSupported');
    }
  }, [trackGAEvent]);

  return (
    <Suspense>
      <div ref={containerRef} id="adsense-container">
        {isIntersected && (
          <DynamicAdsenseForSearch
            query={query}
            searchCategoryName={searchCategoryName}
            pageType={pageType}
            currentPage={currentPage}
            bottomBlockContainerId="adsense-container-bottom-lazy"
            topBlockContainerId={ADSENSE_TOP_CONTAINER_ID}
            channel={channel}
            personalizedAds={isUserConsentPresent.current}
          />
        )}
      </div>
    </Suspense>
  );
};

export default AdsenseContainer;
