interface Pagination {
  limit: number;
  offset: number;
}

export const getCurrentPage = ({ limit, offset }: Pagination): number => Math.ceil(offset / limit) + 1;

interface PaginationResult {
  pageSize: number;
  resultsCount: number;
  currentPage: number;
}

const paginationHelper = (pagination: Pagination, totalResults: number): PaginationResult => {
  const { limit, offset } = pagination;
  return {
    pageSize: limit,
    resultsCount: totalResults,
    currentPage: getCurrentPage({ limit, offset }),
  };
};

export default paginationHelper;
