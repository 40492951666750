import React, { useContext, Fragment, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';

import VIEW_OPTIONS from '@/constants/viewOptions';
import CATEGORIES from '@/constants/categories';
import VERTICALS from '@/constants/verticals';
import { ADSENSE_TOP_CONTAINER_ID } from '@/constants/adsense';
import flagListings from '@/client/actions/flag/flagListings';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import Listing from '@/client/components/Listings/organisms/Listing';
import AdsenseContainer from '@/client/components/AdsenseContainer';
import BannerListItem from '@/client/components/Banner/BannerListItem';
import { TListing } from '~/types/TListing';

const ListingReportFeedback = dynamic(() => import('@/client/components/Listings/atoms/ListingReportFeedback'), {
  ssr: false,
});
const ListingReportButton = dynamic(() => import('@/client/components/Listings/atoms/ListingReportButton'), {
  ssr: false,
});

const TOP_BANNER_OFFSET_LISTVIEW = 2;
const TOP_BANNER_OFFSET_GRIDVIEW = 4;
const MID_BANNER_OFFSET_LISTVIEW = 5;
const MID_BANNER_OFFSET_GRIDVIEW = 12;
const ADSENSE_MID_BANNER_ORDER = 24;

interface ListingsProps {
  topBlock: TListing[];
  listings: TListing[];
  searchRequestObject: TSearchRequest;
  correlationId: string;
  showTopBanner: boolean;
  showMidBanner: boolean;
  showAdsenseMidBanner: boolean;
  isSOIPage: boolean;
  topBannerLoadingState: boolean;
  searchCategoryName: string;
  pageType: 'LRP' | 'ZRP';
  currentPage: number;
  channel: string;
}

const Listings: React.FC<ListingsProps> = ({
  topBlock,
  listings,
  searchRequestObject,
  correlationId,
  showTopBanner,
  showMidBanner,
  showAdsenseMidBanner,
  isSOIPage,
  topBannerLoadingState,
  searchCategoryName,
  pageType,
  currentPage,
  channel,
}) => {
  const { userDetails, xsrfToken } = useContext(EnvironmentContext);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [reportListingSucceeded, setReportListingSucceeded] = useState(false);
  const [reportListingFailed, setReportListingFailed] = useState(false);

  const selectListing = useCallback((itemId: string, checked: boolean) => {
    if (checked) {
      setSelectedListings((prevState) => [...prevState, itemId]);
    } else {
      setSelectedListings((prevState) => prevState.filter((listing) => listing !== itemId));
    }
  }, []);

  const reportSelectedListings = useCallback(async () => {
    try {
      await flagListings({ xsrfToken, itemIds: selectedListings });
      setSelectedListings([]);
      setReportListingSucceeded(true);
      setReportListingFailed(false);
    } catch (e) {
      setReportListingSucceeded(false);
      setReportListingFailed(true);
    }
  }, [selectedListings, xsrfToken]);

  const allListings = [...topBlock, ...listings];
  const { kind } = searchRequestObject.viewOptions;
  const isListView = kind === VIEW_OPTIONS.LIST_VIEW;
  const l1Category = searchRequestObject?.categories?.l1Category?.id;
  const vertical = isListView && l1Category === CATEGORIES.CARS ? VERTICALS.CARS : '';
  const topBannerOffset = isListView ? TOP_BANNER_OFFSET_LISTVIEW : TOP_BANNER_OFFSET_GRIDVIEW;
  const topBannerOrder = allListings.length > topBannerOffset ? topBannerOffset - 1 : allListings.length - 1;
  const midBannerOffset = isListView ? MID_BANNER_OFFSET_LISTVIEW : MID_BANNER_OFFSET_GRIDVIEW;
  const midBannerOrder = showTopBanner ? topBannerOrder + midBannerOffset : midBannerOffset - 1;
  const midBannerPosition = isListView ? 'rubrieks-dt' : 'gvmr1-dt';
  const midBannerType = isListView ? 'fluid' : 'inline-gv';
  const isReportListingsEnabled = userDetails.isLoggedIn && userDetails.isVeroUser;

  return (
    <>
      {isReportListingsEnabled && (
        <>
          <ListingReportFeedback
            reportListingSucceeded={reportListingSucceeded}
            reportListingFailed={reportListingFailed}
          />
          <ListingReportButton
            reportSelectedListings={reportSelectedListings}
            isDisabled={selectedListings.length === 0}
          />
        </>
      )}

      <ul className={getSuitCSSClasses({ namespace: 'hz', name: 'Listings', modifiers: [kind] })}>
        {allListings.map((listing, index) => {
          const shouldShowTopBanner = showTopBanner && index === topBannerOrder;
          const shouldShowMidBanner = showMidBanner && index === midBannerOrder;
          const shouldShowAdsenseBanner = showAdsenseMidBanner && isListView && index === ADSENSE_MID_BANNER_ORDER;

          return (
            <Fragment key={`${listing.itemId}-${index}`}>
              <Listing
                searchRequestObject={searchRequestObject}
                kind={kind}
                {...listing}
                correlationId={correlationId}
                isSelectable={isReportListingsEnabled}
                selectAction={selectListing}
                location={listing.location}
                isSelected={selectedListings.includes(listing.itemId)}
                isFavorited={listing.isFavorited}
                vertical={vertical}
                isSOIPage={isSOIPage}
                index={index}
              />

              {!!shouldShowTopBanner && (
                <BannerListItem position="top-dt" type="fluid" loading={topBannerLoadingState} />
              )}

              {!!shouldShowMidBanner && <BannerListItem position={midBannerPosition} type={midBannerType} />}

              {!!shouldShowAdsenseBanner && (
                <li suppressHydrationWarning id={ADSENSE_TOP_CONTAINER_ID}>
                  <AdsenseContainer
                    query={searchRequestObject?.searchQuery}
                    searchCategoryName={searchCategoryName}
                    pageType={pageType}
                    currentPage={currentPage}
                    channel={channel}
                  />
                </li>
              )}
            </Fragment>
          );
        })}
      </ul>
    </>
  );
};

export default Listings;
