import memoize from './memoize';

const NAME_SPACE = 'mp';

const format = (prefix: string = '', input: string[] = []): string =>
  input
    .filter((value) => Boolean(value))
    .map((value) => `${prefix}${value}`)
    .join(' ');

interface ComponentNameInput {
  namespace: string;
  parent?: string;
  name: string;
}

const getComponentName = ({ namespace, parent, name }: ComponentNameInput): string => {
  if (parent) {
    return `${namespace}-${parent}-${name}`;
  }
  return `${namespace}-${name}`;
};

interface SuitCSSClassesInput {
  namespace?: string;
  name?: string;
  parent?: string;
  states?: string[];
  modifiers?: string[];
  utilities?: string[];
  icon?: string;
  custom?: string;
}

const getSuitCSSClasses = ({
  namespace = NAME_SPACE,
  name = '',
  parent = '',
  states = [],
  modifiers = [],
  utilities = [],
  icon = '',
  custom = '',
}: SuitCSSClassesInput = {}): string => {
  if (!name) {
    return '';
  }

  const componentClassName = getComponentName({
    namespace,
    parent,
    name,
  });

  const classNames = [
    componentClassName,
    format(`${componentClassName}--`, modifiers),
    format('is-', states),
    format('u-', utilities),
    format(`${namespace}-svg-`, [icon]),
    custom || '',
  ];

  return classNames.filter((className) => Boolean(className)).join(' ');
};

export default memoize(getSuitCSSClasses);
