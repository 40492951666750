interface Attribute {
  attributeValueKey: string;
}

interface Attributes {
  [key: string]: Attribute[];
}

interface BannerAttribute {
  label: string;
  value: string[];
}

const createAttributesConfig = (attributes: Attributes = {}): BannerAttribute[] => {
  const keys = Object.keys(attributes);
  const bannerAttributes: BannerAttribute[] = [];

  keys.forEach((key) => {
    const item: BannerAttribute = {
      label: key,
      value: [],
    };
    attributes[key].forEach((attribute) => {
      item.value.push(attribute.attributeValueKey.toLowerCase().replace(/ /g, '-'));
    });
    bannerAttributes.push(item);
  });

  return bannerAttributes;
};

export default createAttributesConfig;
