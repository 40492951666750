interface experimentsByTagProps {
  labsConfig: TLabsConfig;
  tag: string;
}

const getExperimentsByTag = ({
  labsConfig,
  tag,
}: experimentsByTagProps): Array<{ key: string; tags: string[]; group: string }> => {
  if (labsConfig && !labsConfig.abSwitches) {
    return [];
  }

  return Object.keys(labsConfig.abSwitches)
    .filter((key) => labsConfig.abSwitches[key].tags.indexOf(tag) > -1)
    .map((key) => Object.assign(labsConfig.abSwitches[key], { key }));
};

export default getExperimentsByTag;
