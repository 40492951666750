import replaceAll from '@/utils/strings/replaceAll';

/**
 * Replaces all occurrences of replaceObj's keys with their corresponding values in the passed-in string
 *
 * @param str - The original string
 * @param replaceObj - An object with keys to be replaced and their corresponding replacement values
 * @return The modified string with replacements
 */
const replaceAllFromObject = (str: string, replaceObj: Record<string, string>): string =>
  Object.keys(replaceObj).reduce((acc, target) => {
    const replaceBy = replaceObj[target];
    return replaceAll(acc, target, replaceBy);
  }, str);

export default replaceAllFromObject;
