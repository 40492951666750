import serialize from 'serialize-javascript';

/**
 * Returns safe serialized JSON that can be used inside a script tag
 *
 * Serialized data should not contain any function or regexps values
 *
 * @param data - The data to be serialized
 * @returns The serialized object as a string
 */
const safeJsonSerialize = (data: object): string => serialize(data, { isJSON: true });

export default safeJsonSerialize;
