import { mileage as numberFormatter } from '@ecg-marktplaats/aurora-js-formatters';
import priceFormatter from './priceFormatter';
import { attributeKeys } from '../../../constants/attributes/nonStandards';

type TranslatorFunction = (key: string, options?: Record<string, any>) => string;

const formatBasedOnKey = (
  key: string,
  value: any,
  suppressZeroCents?: boolean,
  t?: TranslatorFunction,
): string | null => {
  if (typeof value === 'undefined' || value === null) {
    return null;
  }
  if (key === attributeKeys.PRICE_CENTS) {
    if (typeof value === 'number') {
      return priceFormatter({ priceCents: value, suppressZeroCents, priceType: 'FIXED' });
    }
    if (typeof value.priceCents === 'undefined' || value.priceCents === null) {
      return null;
    }
    return priceFormatter(value);
  }
  if (key === attributeKeys.MILEAGE) {
    const mileageValue = numberFormatter(value);
    if (t) {
      return t('viewControls.chips.mileage', { range: mileageValue });
    } else {
      return mileageValue;
    }
  }
  return value;
};

export default formatBasedOnKey;
