interface FormatterParams {
  key: string;
  argumentValue: string;
}

const trustIndicatorFormatter = (
  { key, argumentValue }: FormatterParams,
  i18n: { t: (key: string, options?: any) => string },
): string => {
  const highlightKey = `listings.highlights.${key}`;
  if (key.toLowerCase() === 'warranty') {
    if (!argumentValue) {
      return i18n.t(highlightKey);
    }
    // TODO: Avoid searching for dutch text
    if (argumentValue.toLowerCase().includes('garantie')) {
      return i18n.t(argumentValue);
    }
    return i18n.t(`listings.highlights.DetailedWarranty`, { warrantyLabel: argumentValue });
  }
  return i18n.t(highlightKey, { argumentValue });
};

export default trustIndicatorFormatter;
