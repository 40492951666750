import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { ListingSellerExternalLink } from '@/client/components/Listings/atoms';
import { imageFormatter } from '@/client/utils/formatters';
import ListingVipLink from '@/client/components/Listings/atoms/ListingVipLink';
import ListingGalleryViewFigure from '@/client/components/Listings/molecules/ListingGalleryViewFigure';
import { Attribute } from '@/client/types/Listings';
import { TImage } from '@/client/types/Images';
interface ListingGalleryViewBottomBlockContentProps {
  title: string;
  pictures?: TImage[];
  price: string;
  sellerInformation: TSellerInformation;
  loadingType?: 'eager' | 'lazy';
  decodingType?: 'sync' | 'async' | 'auto';
  vipUrl?: string;
  trackingData?: string;
  correlationId?: string;
  pageLocation?: string;
  thinContent?: boolean;
  categories?: TCategories;
  extendedAttributes?: Attribute[];
}

const ListingGalleryViewBottomBlockContent: React.FC<ListingGalleryViewBottomBlockContentProps> = ({
  title,
  price,
  sellerInformation,
  pictures,
  loadingType,
  decodingType,
  vipUrl,
  trackingData,
  correlationId,
  pageLocation,
  thinContent,
  categories,
  extendedAttributes,
}) => {
  return (
    <ListingVipLink
      vipUrl={vipUrl}
      trackingData={trackingData}
      correlationId={correlationId}
      pageLocation={pageLocation}
      thinContent={thinContent}
    >
      <ListingGalleryViewFigure
        images={imageFormatter(pictures ?? [], title, categories, extendedAttributes)}
        loadingType={loadingType}
        decodingType={decodingType}
      />
      <div className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-content' })}>
        <div>
          <h3 className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-title' })}>{title}</h3>
          <span
            className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-price', custom: 'hz-text-price-label' })}
          >
            {price}
          </span>
        </div>
        <div>
          <span className={getSuitCSSClasses({ namespace: 'hz', name: 'Listing-seller-link' })}>
            <ListingSellerExternalLink
              url={sellerInformation.sellerWebsiteUrl}
              className="hz-Listing--casGallery__goToLink"
            >
              <Trans tagName="span" i18nKey="listings.visit" />
            </ListingSellerExternalLink>
          </span>
        </div>
      </div>
    </ListingVipLink>
  );
};

// todo: find which properties are really needs to be checked for preventing rerendering
function arePropsEqual(
  prevProps: ListingGalleryViewBottomBlockContentProps,
  nextProps: ListingGalleryViewBottomBlockContentProps,
) {
  return isEqual(prevProps, nextProps);
}

export default memo(ListingGalleryViewBottomBlockContent, arePropsEqual);
