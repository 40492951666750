import React, { memo, useContext, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import dynamic from 'next/dynamic';
import getSuitCSSClasses from '@/utils/getSuitCSSClasses';
import { useI18nContext, Trans } from '@ecg-marktplaats/js-react-i18n';
import { ImageSize } from '@ecg-marktplaats/media-util-js-lib';
import { Divider, Icon, NeutralBadge, Asset } from '@hz-design-system/web-ui';

import ListingOtherSeller from '@/client/components/Listings/molecules/ListingOtherSeller';

import {
  ListingAttribute,
  ListingSellerName,
  ListingEnergyLabel,
  ListingDetailsButton,
} from '@/client/components/Listings/atoms';

const ListingSellerExternalLink = dynamic(
  () => import('@/client/components/Listings/atoms/ListingSellerExternalLink'),
  { ssr: false },
);

import { imageFormatter, opvalStickerFormatter, imageSizeFormatter } from '@/client/utils/formatters';
import formatAttributeBasedOnKey from '@/client/utils/formatters/formatBasedOnKey';
import ListingListViewFigure from '@/client/components/Listings/molecules/ListingListViewFigure';
import ListingVipLink from '@/client/components/Listings/atoms/ListingVipLink';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import SellerExternalWebsite from './SellerExternalWebsite';
import getIsMobile from '@/client/utils/browser/isMobile';
import { Attribute } from '@/client/types/Listings';
import { TImage } from '@/client/types/Images';

const SPACE_SEPARATOR = ' ';

type TListingListViewContent = {
  attributes: Attribute[];
  categories?: TCategories;
  categorySpecificDescription?: string;
  correlationId?: string;
  date: string;
  decodingType?: 'sync' | 'async' | 'auto';
  description: string;
  extendedAttributes?: Attribute[];
  isBottomBlock?: boolean;
  isFavorite?: boolean;
  l1Category?: number;
  loadingType?: 'eager' | 'lazy';
  location: { label: string; distance?: string };
  napAvailable?: boolean;
  onFavorite: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  opvalStickerText?: string;
  otherSellerListings: TOtherSellerListings;
  otherSellerUrl?: string;
  pageLocation?: string;
  pictures?: TImage[];
  price: string;
  priorityProduct?: string;
  sellerInformation?: TSellerInformation;
  shouldDisplayExtendedContent?: boolean;
  thinContent?: boolean;
  title: string;
  trackingData?: string;
  trustIndicators?: { value?: string; key: string }[];
  urgencyFeatureActive?: boolean;
  verticals?: string[];
  vipUrl: string;
};

const ListingListViewContent = ({
  attributes = [],
  categories,
  categorySpecificDescription,
  correlationId,
  date,
  decodingType,
  description,
  extendedAttributes = [],
  isBottomBlock = false,
  isFavorite = false,
  l1Category,
  loadingType,
  location,
  napAvailable = false,
  onFavorite,
  opvalStickerText,
  otherSellerListings,
  otherSellerUrl,
  pageLocation,
  pictures,
  price,
  priorityProduct = '',
  sellerInformation,
  shouldDisplayExtendedContent = false,
  thinContent,
  title,
  trackingData,
  trustIndicators = [],
  urgencyFeatureActive = false,
  verticals = [],
  vipUrl,
}: TListingListViewContent) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { tenantContext } = useContext(EnvironmentContext);
  const { t } = useI18nContext();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(getIsMobile());
  }, []);

  const carPassUrlKey = 'carPassUrl';
  const carPassUrl = attributes.find((x) => x.key === carPassUrlKey);
  const energyLabel = attributes.find((x) => x.key === 'energyLabel');
  const mileage = attributes.find((x) => x.key === 'mileage');
  const isVerifiedRefurbishedAd = attributes.find((x) => x.key === 'deeplink');
  const isVerifiedSeller = Boolean(sellerInformation?.isVerified && !verticals.includes('automotive'));
  const regularAttributes = attributes.filter(
    (x) =>
      x.key !== 'energyLabel' &&
      x.key !== carPassUrlKey &&
      x.key !== 'mileage' &&
      x.key !== 'options' &&
      x.key !== 'model' &&
      x.key !== 'body' &&
      x.key !== 'fuel' &&
      x.key !== 'deeplink',
  );
  let priceExtendedDetailsClassName = getSuitCSSClasses({
    namespace: 'hz',
    name: 'Listing-price-extended-details',
  });
  if (isDetailsOpen) {
    priceExtendedDetailsClassName = getSuitCSSClasses({
      namespace: 'hz',
      name: 'Listing-price-extended-details',
      modifiers: ['details-open'],
    });
  } else if (shouldDisplayExtendedContent) {
    priceExtendedDetailsClassName = getSuitCSSClasses({
      namespace: 'hz',
      name: 'Listing-price-extended-details',
    });
  }

  return (
    <div
      className={getSuitCSSClasses({
        namespace: 'hz',
        name: 'Listing-listview-content',
      })}
    >
      <div
        className={getSuitCSSClasses({
          namespace: 'hz',
          name: 'Listing-item-wrapper',
        })}
      >
        <ListingVipLink
          vipUrl={vipUrl}
          trackingData={trackingData}
          correlationId={correlationId}
          pageLocation={pageLocation}
          thinContent={thinContent}
          className={getSuitCSSClasses({
            namespace: 'hz',
            name: 'Listing-coverLink',
          })}
        >
          <ListingListViewFigure
            images={imageFormatter(pictures ?? [], title, categories, extendedAttributes)}
            opvalStickerText={opvalStickerFormatter(opvalStickerText)}
            loadingType={loadingType}
            decodingType={decodingType}
            isFavorite={isFavorite}
            onFavorite={onFavorite}
            l1Category={l1Category}
            isDetailsOpen={shouldDisplayExtendedContent && isDetailsOpen}
          />
          <div
            className={getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-group',
            })}
          >
            <div
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-group--title-description-attributes',
              })}
            >
              <h3
                suppressHydrationWarning
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-title',
                })}
              >
                {title}
              </h3>
              <div className={priceExtendedDetailsClassName}>
                <p
                  suppressHydrationWarning
                  className={getSuitCSSClasses({
                    namespace: 'hz',
                    name: 'Listing-price',
                    custom: 'hz-text-price-label',
                    modifiers: ['mobile'],
                  })}
                >
                  {price}
                </p>
                {shouldDisplayExtendedContent && (
                  <ListingDetailsButton isDetailsOpen={isDetailsOpen} setIsDetailsOpen={setIsDetailsOpen} />
                )}
              </div>
              <p
                suppressHydrationWarning
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-description',
                  custom: 'hz-text-paragraph',
                })}
              >
                {description}
              </p>
              {urgencyFeatureActive && (
                <ListingAttribute type="micro-tip">
                  <Trans tagName="span" i18nKey="listings.moet-nu-weg" />
                </ListingAttribute>
              )}
              {isVerifiedRefurbishedAd && (
                <div className="hz-Listing-refurbished-item">
                  <Icon name="charity" color="signal-success-default" size="s" />
                  <p>Geverifieerd refurbished</p>
                </div>
              )}
              <div
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-trust-items-and-attributes',
                })}
              >
                <div
                  className={getSuitCSSClasses({
                    namespace: 'hz',
                    name: 'Listing-bottom-info-divider-wrapper',
                  })}
                >
                  {(priorityProduct !== 'NONE' ||
                    !!regularAttributes.length ||
                    mileage ||
                    carPassUrl ||
                    napAvailable ||
                    energyLabel ||
                    sellerInformation?.sellerWebsiteUrl) && (
                    <Divider
                      className={getSuitCSSClasses({
                        namespace: 'hz',
                        name: 'Listing-bottom-info-divider',
                      })}
                    />
                  )}
                  {(!!regularAttributes.length || mileage || carPassUrl || napAvailable || energyLabel) && (
                    <>
                      <div
                        className={getSuitCSSClasses({
                          namespace: 'hz',
                          name: 'Listing-attributes',
                        })}
                      >
                        {energyLabel && <ListingEnergyLabel rating={energyLabel.value} tenantContext={tenantContext} />}
                        {regularAttributes.map((attribute, key) => (
                          <ListingAttribute type="default" key={key}>
                            {formatAttributeBasedOnKey(attribute.key, attribute.value, undefined, undefined)}
                            {SPACE_SEPARATOR}
                            {attribute.unit && attribute.unit}
                          </ListingAttribute>
                        ))}
                        {(mileage || carPassUrl || napAvailable) && (
                          <div
                            className={getSuitCSSClasses({
                              namespace: 'hz',
                              name: 'Listing-attributes-nap-mileage',
                            })}
                          >
                            {mileage && (
                              <ListingAttribute type="default">
                                {formatAttributeBasedOnKey(mileage.key, mileage.value, undefined, undefined)}
                                {/* space separator between attribute name and value */} {mileage.unit && mileage.unit}
                                {napAvailable && (
                                  <span title={t('listings.nap')}>
                                    <Icon name="checkmark" color="success" size="s" />
                                  </span>
                                )}
                                {carPassUrl && (
                                  <span title={t('listings.carPass')}>
                                    <Icon name="checkmark" color="signal-link-default" size="m" />
                                  </span>
                                )}
                              </ListingAttribute>
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {carPassUrl && isMobile && (
                  <ListingAttribute type="nap">
                    <Asset.Fluid
                      className={getSuitCSSClasses({
                        namespace: 'hz',
                        name: 'Listing-attributes-carpass',
                      })}
                      name="LogoCarPass"
                      alt="check car-pass"
                    />
                  </ListingAttribute>
                )}
                {napAvailable && isMobile && (
                  <ListingAttribute type="nap">
                    <Icon name="checkmark-circle" color="success" size="sm" />
                    <Trans tagName="span" i18nKey="listings.nap" />
                  </ListingAttribute>
                )}
                {!!trustIndicators?.length && (
                  <div
                    className={getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Listing-trust-items',
                    })}
                  >
                    {trustIndicators.map((trustIndicator, key) =>
                      isMobile ? (
                        <div
                          className={getSuitCSSClasses({
                            namespace: 'hz',
                            name: 'Attribute',
                            modifiers: ['trust-item'],
                          })}
                          key={key}
                        >
                          <Icon name="checkmark-circle" color="success" size="sm" />
                          <span>{trustIndicator.value}</span>
                        </div>
                      ) : (
                        <NeutralBadge
                          className={getSuitCSSClasses({
                            namespace: 'hz',
                            name: 'Listing-trust-item',
                          })}
                          key={key}
                        >
                          <Trans tagName="span" i18nKey={`trustIndicator.${trustIndicator.key}`} />
                        </NeutralBadge>
                      ),
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              suppressHydrationWarning
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-group--price-date-feature',
              })}
            >
              <span
                suppressHydrationWarning
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-price',
                  custom: 'hz-text-price-label',
                  modifiers: ['desktop'],
                })}
              >
                {price}
              </span>
              <span
                suppressHydrationWarning
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-date',
                  modifiers: ['desktop'],
                })}
              >
                {date}
              </span>
              <span
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-priority',
                  modifiers: ['all-devices'],
                })}
              >
                <Trans tagName="span" i18nKey={`priorityProduct.${priorityProduct}`} />
              </span>
            </div>

            {shouldDisplayExtendedContent && (
              <details
                className={
                  isDetailsOpen
                    ? getSuitCSSClasses({
                        namespace: 'hz',
                        name: 'Listing-extended-content',
                        modifiers: ['open'],
                      })
                    : getSuitCSSClasses({
                        namespace: 'hz',
                        name: 'Listing-extended-content',
                      })
                }
                open
              >
                <summary></summary>
                <div
                  className={getSuitCSSClasses({
                    namespace: 'hz',
                    name: 'Listing-extended-attributes',
                  })}
                >
                  {extendedAttributes?.map((attribute) => (
                    <ListingAttribute key={attribute.key}>
                      {formatAttributeBasedOnKey(attribute.key, attribute.value, undefined, undefined)}
                      {SPACE_SEPARATOR}
                      {attribute.unit && attribute.unit}
                    </ListingAttribute>
                  ))}
                </div>

                {
                  <p
                    suppressHydrationWarning
                    className={getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Listing-description',
                      modifiers: ['extended'],
                    })}
                  >
                    {categorySpecificDescription}
                  </p>
                }
              </details>
            )}
            {isVerifiedRefurbishedAd && (
              <div className="hz-Listing-refurbished-item-details">
                <Icon name="charity" color="signal-success-default" size="s" />
                <p>Geverifieerd refurbished</p>
              </div>
            )}
          </div>
        </ListingVipLink>

        <div
          suppressHydrationWarning
          className={getSuitCSSClasses({
            namespace: 'hz',
            name: 'Listing--sellerInfo',
          })}
        >
          {sellerInformation && (
            <ListingSellerName link={sellerInformation.soiUrl} isVerifiedSeller={isVerifiedSeller}>
              {sellerInformation.sellerName}
            </ListingSellerName>
          )}
          {!isBottomBlock && (!!location.label || !!location.distance) && (
            <span
              suppressHydrationWarning
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-location',
              })}
            >
              <span
                suppressHydrationWarning
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-distance-label',
                })}
              >
                {location.label}
              </span>
              {location.distance && (
                <span
                  className={getSuitCSSClasses({
                    namespace: 'hz',
                    name: 'Listing-distance',
                  })}
                >
                  {location.distance}
                </span>
              )}
            </span>
          )}

          {sellerInformation?.sellerLogoUrl && (
            <span
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-seller-logo',
              })}
            >
              <img
                src={imageSizeFormatter({
                  imageUrlFromApi: sellerInformation.sellerLogoUrl,
                  customSize: ImageSize.Medium,
                })}
                alt=""
              />
            </span>
          )}

          <div
            suppressHydrationWarning
            className={getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-group--price-date-feature',
              modifiers: ['tablet'],
            })}
          >
            <span
              suppressHydrationWarning
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-date',
              })}
            >
              {date}
            </span>
            <span
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-priority',
                modifiers: ['all-devices'],
              })}
            >
              <Trans tagName="span" i18nKey={`priorityProduct.${priorityProduct}`} />
            </span>
          </div>
          <span
            className={getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-seller-link',
            })}
            suppressHydrationWarning
          >
            {!sellerInformation?.sellerLogoUrl && sellerInformation?.showWebsiteUrl && (
              <ListingSellerExternalLink
                url={sellerInformation?.sellerWebsiteUrl}
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'TextLink',
                })}
              >
                <Trans tagName="span" i18nKey="listings.visit" />
              </ListingSellerExternalLink>
            )}
          </span>
        </div>
        <div
          className={getSuitCSSClasses({
            namespace: 'hz',
            name: 'Listing-group--mobile-bottom-row',
          })}
        >
          <div suppressHydrationWarning>
            {sellerInformation?.showWebsiteUrl && sellerInformation?.sellerWebsiteUrl && (
              <SellerExternalWebsite sellerWebsiteUrl={sellerInformation.sellerWebsiteUrl} />
            )}
            {!sellerInformation?.showWebsiteUrl && (!!location.label || !!location.distance) && (
              <span
                suppressHydrationWarning
                className={getSuitCSSClasses({
                  namespace: 'hz',
                  name: 'Listing-location',
                })}
              >
                {location.label && (
                  <span
                    suppressHydrationWarning
                    className={getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Listing-distance-label',
                    })}
                  >
                    {location.label}
                    {location.distance && <>&nbsp;· </>}
                  </span>
                )}
                {location.distance && (
                  <span
                    className={getSuitCSSClasses({
                      namespace: 'hz',
                      name: 'Listing-distance',
                    })}
                  >
                    {location.distance}
                  </span>
                )}
              </span>
            )}
          </div>
          <div>
            <span
              className={getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-priority',
                modifiers: ['all-devices'],
              })}
            >
              <Trans tagName="span" i18nKey={`priorityProduct.${priorityProduct}`} />
            </span>
            <span
              suppressHydrationWarning
              className={`${getSuitCSSClasses({
                namespace: 'hz',
                name: 'Listing-date',
              })} hz-bottom-right`}
            >
              {date}
            </span>
          </div>
        </div>
      </div>
      {otherSellerListings && otherSellerUrl && otherSellerListings.totalSellerListingsResults > 1 && !isMobile && (
        <div>
          <Divider
            className={getSuitCSSClasses({
              namespace: 'hz',
              name: 'Listing-seller-other-items-divider',
            })}
          />
          <ListingOtherSeller
            otherSellerListings={otherSellerListings}
            pageLocation={pageLocation}
            correlationId={correlationId}
            url={otherSellerUrl}
          />
        </div>
      )}
    </div>
  );
};

// todo: find which properties are really needs to be checked for preventing rerendering
function arePropsEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(ListingListViewContent, arePropsEqual);
