/* eslint-disable no-console */
import React, { useContext, useEffect, useState, lazy, Suspense, useMemo, useRef } from 'react';

import Head from 'next/head';
import dynamic from 'next/dynamic';

import { PageElement } from '@hz-design-system/web-ui';

// Constants imports
import CATEGORIES_L1 from '@/constants/categories';
import VIEW_OPTIONS from '@/constants/viewOptions';
import { MIN_NUMBER_OF_LISTINGS_DDI } from '@/constants/seo';
import { SORT_BY } from '@/constants/sorting';
import COOKIES from '@/constants/cookies';

// Utils imports
import { getCookie } from '@/client/utils/cookies';
import experiments from '@/utils/labs/experiments';
import getChannel from '@/client/utils/adsense/getChannel';
import { buildSmartLinkParameters, iosSmartBanner } from '@/utils/links/smartLinksHelpers';
import createBannerConfig from '@/client/utils/banners/createBannerConfig';
import { PriceSuggestion } from '@/client/utils/seo/types';
import { getSearchCategoryName, getPageType, enrichLocaleAlt } from '@/client/utils/seoUtils';
import { adobeRegistration, sendEvent } from '@/client/utils/audience-targeting';
import getIsMobile from '@/client/utils/browser/isMobile';
import getDominantCategory from '@/utils/getDominantCategory';
import isExcludedCategory from '@/utils/seo/isExcludedCategory';
import getRobotsContent from '@/client/utils/seo/robotsContent';
import trackEvent from '@/client/utils/analytics/trackEvent';
import stripHtml from '@/client/utils/seo/stripHtml';

// Helper imports
import paginationHelper from '@/common/helpers/paginationHelper';
import createAttributesConfig from '@/common/helpers/banners/createAttributesConfig';

// Action, context and hook imports
import actionStatus from '@/client/actions/actionStatus';
import storeSearches from '@/client/actions/storage/storeSearches';
import EnvironmentContext from '@/client/contexts/EnvironmentContext';
import useCesSurvey from '@/client/hooks/useCesSurvey';

// Non-lazy imports of components
import { formatBreadcrumbs } from '@/client/components/Breadcrumb';
import SellerHeaderContainer from '@/client/components/molecules/SellerHeaderContainer';
import PageContainer from '@/client/components/Page/PageContainer';
import ZRPSearchHelper from '@/client/components/ZRPSearchHelper';
import ZrpSaveSearchHelper from '@/client/components/ZRPSearchHelper/ZrpSaveSearchHelper';
import SeoMetaTags from '@/client/components/Seo/SeoMetaTags';
import SSOIZeroResultsBox from '@/client/components/SSOIZeroResults/SSOIZeroResultsBox';
import NDFCGenericRankingsText from '@/client/components/NDFCGenericRankingsText/NDFCGenericRankingsText';
import Notification from '@/client/components/molecules/Notification';
import Banner from '@/client/components/Banner/Banner';
import Listings from '@/client/components/Listings/Listings';
import FilterSidebar from '@/client/components/FilterSidebar';
import Breadcrumb from '@/client/components/Breadcrumb/Breadcrumb';
import FilterChips from '@/client/components/FilterChips';
import ViewControls from '@/client/components/ViewControls';

const Dac7PromoBanner = dynamic(() => import('@/client/components/Dac7PromoBanner/Dac7PromoBanner'), {
  ssr: false,
});

const ShippingCampaignBanner = dynamic(
  () => import('@/client/components/ShippingCampaignBanner/ShippingCampaignBanner'),
  {
    ssr: false,
  },
);

// Lazy imports of components
const AdsenseContainer = lazy(() => import('@/client/components/AdsenseContainer'));
const FaqFooter = lazy(() => import('@/client/components/FaqFooter'));
const SuggestedSearches = lazy(() => import('@/client/components/SuggestedSearches'));
const DataDrivenContent = lazy(() => import('@/client/components/Seo/DataDrivenContent'));
const PaginationControls = lazy(() => import('@/client/components/PaginationControls'));
const SaveSearchContainer = lazy(() => import('@/client/components/molecules/SaveSearchContainer'));
const BottomBlockLazyListings = lazy(() => import('@/client/components/Listings/BottomBlockLazyListings'));
const AndroidSmartBanner = lazy(() => import('@/client/components/LinkGenerator/AndroidSmartBanner'));
const SmartBannerLarge = lazy(() => import('@/client/components/SmartBanner/SmartBannerLarge'));
const HubPages = lazy(() => import('@/client/components/HubPages'));

interface FaqData {
  question: string;
  answer: string;
}

interface InternalLink {
  linkTo: string;
  anchorText: string;
}

interface LRPContainerProps {
  meta: TSeoMetaTags;
  hrefLangUrl: string;
  requestStatus: string;
  searchRequestAndResponse: TSearchRequestAndResponse;
  seller: TSeller;
  headerProps: {
    searchQuery?: string;
    loginTargetUrl?: string;
    [key: string]: any;
  };
  faqData?: {
    generatedFlag: boolean;
    data: FaqData[];
  };
  internalLinks: InternalLink;
  categories?: TCategories;
  hubPage: { content: string };
  relevantInlinks?: { url: string; anchorText: string }[];
  priceSuggestions: PriceSuggestion[];
}

const TOP_BANNER_SLOT = 'banner-top-dt';
const allowSavedSearch = true;

const LrpContainer: React.FC<LRPContainerProps> = ({
  meta,
  hrefLangUrl,
  requestStatus,
  searchRequestAndResponse,
  seller,
  headerProps,
  faqData,
  internalLinks,
  hubPage,
  relevantInlinks,
  priceSuggestions,
}) => {
  const {
    xsrfToken,
    tenantContext,
    audienceTargeting,
    userDetails,
    properties,
    urls,
    labsConfig,
    isBot,
    useSourcePointCMP,
    seoProperties,
    showExperimentalSmartBanner,
    showShippingCampaignBanner,
    consent,
  } = useContext(EnvironmentContext);

  const { adblockerFile, mainScriptUrl, gptUrl, gptBatchMetricsEndpointUrl } = urls || {};
  const {
    gclidQueryParam,
    androidPackage,
    androidScheme,
    analyticsCode,
    iosAppId,
    iosProtocol,
    malvertisingProtectionId,
  } = properties || {};

  const {
    isSearchSaved = false,
    attributeHierarchy,
    categoriesById,
    originalQuery,
    searchRequest,
    searchCategory,
    facets,
    totalResultCount,
    hasErrors,
    metaTags,
    sortOptions,
    listings,
    topBlock,
    correlationId,
    correctedQuery,
    seoFriendlyAttributes,
    seoFriendlyTextAttributes,
    alternativeLocales,
  } = searchRequestAndResponse as TSearchRequestAndResponse;

  const { hostName = '', localeAlt, searchDistanceOptions, sharedDomainCookie } = headerProps;

  const [notification, setNotification] = useState<null | any>(null);
  const [isMobileFilterDialogOpen, setIsMobileFilterDialogOpen] = useState<boolean>(false);
  const [saveSearchButtonDisabled, setSaveSearchButtonDisabled] = useState<boolean>(isSearchSaved);
  const [topBannerLoadingState, setTopBannerLoadingState] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [bannersInitialized, setBannersInitialized] = useState<boolean>(false);
  const [showSmartBanner, setShowSmartBanner] = useState<boolean>(false);
  const [isWide, setIsWide] = useState<boolean>(false);
  const { loadSurvey } = useCesSurvey();
  const experimentsConfig = experiments({ labsConfig });
  const postponeBannersUntilClientSideSearch = useRef(false);

  const {
    isSmartBannerExperimentEnabled,
    isShippingCampaignEnabled,
    isDAC7CommunicationEnabled,
    isFetchResultsOnceAllCatEnabled,
  } = experimentsConfig;

  const isCarCategory = Boolean(categoriesById[CATEGORIES_L1.CARS]);
  const isRequestCompleted = [actionStatus.failure, actionStatus.success].includes(requestStatus);
  const isRequestSuccess = requestStatus === actionStatus.success;
  const isTenOrMoreListings = totalResultCount && totalResultCount >= MIN_NUMBER_OF_LISTINGS_DDI;
  const shouldShowHubpageContent = !seller && !!hubPage;
  const bannerProperties = {
    adblockerFile,
    mainScriptUrl,
    gptUrl,
    gptBatchMetricsEndpointUrl,
    malvertisingProtectionId,
    analyticsCode,
  };
  const isSSOI = userDetails.isLoggedIn && seller && userDetails.id === seller.id;

  const dominantCategory = getDominantCategory(facets);

  const createBannerData = async () => {
    const { distance: { postcode } = {}, viewOptions } = searchRequest;
    const rsiSegments = getCookie(COOKIES.RSI_SEGMENTS) || '';

    const createdBannerConfig = await createBannerConfig({
      xsrfToken,
      adblockerFile,
      attributes: createAttributesConfig(attributeHierarchy),
      bannerProperties,
      fingerPrint: audienceTargeting?.fingerPrint,
      hostname: hostName,
      labsConfig,
      loggedInUser: userDetails,
      mainScriptUrl,
      postcode,
      query: originalQuery,
      rsiSegments,
      seller,
      searchRequest,
      tenantContext,
      useSourcePointCMP,
      view: viewOptions ? viewOptions.kind : '',
      dominantCategory,
      consent,
    });

    window.mpBannerLibConfig = createdBannerConfig;
    return createdBannerConfig;
  };

  const initBanners = (createdBannerConfig) => {
    if (!window.googletag) {
      window.googletag = { cmd: [] };
    } else if (!window.googletag.cmd) {
      window.googletag.cmd = [];
    }

    const bannerLibConfig = window?.mpBannerLibConfig?.mainScriptUrl ? window?.mpBannerLibConfig : createdBannerConfig;

    const bannerScript = document.createElement('script');
    bannerScript.setAttribute('data-testid', 'banner-script');
    bannerScript.setAttribute('src', bannerLibConfig?.mainScriptUrl);
    document.body.appendChild(bannerScript);

    const onLoadHandler = (event: any) => {
      if (event.detail?.elementId === TOP_BANNER_SLOT) {
        console.log('gptSlotOnLoad');
        setTopBannerLoadingState(false);
      }
    };

    window.addEventListener('gptSlotOnLoad', onLoadHandler);
    return () => window.removeEventListener('gptSlotOnLoad', onLoadHandler);
  };

  const initAudienceTargeting = () => {
    adobeRegistration({ audienceTargeting, user: userDetails, tenantContext });
  };

  const trackAudienceTargetingEvent = () => {
    if (!audienceTargeting) return;
    sendEvent({
      audienceTargeting,
      user: userDetails,
      tenantContext,
      searchRequestAndResponse,
      xsrfToken,
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia('(min-width: 1200px)');
      setIsWide(mediaQuery.matches);

      const handleResize = () => setIsWide(mediaQuery.matches);
      mediaQuery.addEventListener('change', handleResize);

      return () => {
        mediaQuery.removeEventListener('change', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(getIsMobile());

    const init = async (createdBannerConfig) => {
      postponeBannersUntilClientSideSearch.current = Boolean(window.location.hash);
      await initBanners(createdBannerConfig);
      initAudienceTargeting();
      setIsMobile(getIsMobile());
      window.addEventListener('resize', handleResize);
    };

    const resetBanners = async () => {
      const createdBannerConfig = await createBannerData();

      if (!bannersInitialized) {
        await init(createdBannerConfig);
        setBannersInitialized(true);
      } else {
        window.ecgJsBanners?.resetAllBannersInPage();
      }

      if (!audienceTargeting) return;
      trackAudienceTargetingEvent();
    };

    const resetBannersIfNeeded = async () => {
      if (!postponeBannersUntilClientSideSearch.current) {
        await resetBanners();
      }
      postponeBannersUntilClientSideSearch.current = false;
    };

    resetBannersIfNeeded();
    (window as any).cssVars?.();
    return () => window.removeEventListener('resize', handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchRequest]);

  useEffect(() => {
    if ([actionStatus.failure, actionStatus.success].includes(requestStatus)) {
      setNotification(null);
    } else {
      setTopBannerLoadingState(true);
    }
  }, [requestStatus]);

  useEffect(() => {
    if (isCarCategory && userDetails.isLoggedIn && !seller && isRequestSuccess) {
      loadSurvey();
    }
  }, [isCarCategory, isRequestSuccess, loadSurvey, seller, userDetails.isLoggedIn]);

  useEffect(() => {
    if (searchRequest.categories?.l1Category && searchRequestAndResponse.facets) {
      storeSearches(searchRequest, searchRequestAndResponse.facets);
    }
  }, [searchRequest, searchRequest.categories?.l1Category, searchRequestAndResponse]);

  useEffect(() => {
    setSaveSearchButtonDisabled(isSearchSaved);
  }, [isSearchSaved, searchRequest]);

  const showMobileFilterDialog = () => {
    if (!isMobile) return;

    setIsMobileFilterDialogOpen(true);
    trackEvent({ eventAction: 'LrpFiltersMobile', eventLabel: 'open' });
  };

  const hideMobileFilterDialog = () => {
    if (!isMobile) return;

    setIsMobileFilterDialogOpen(false);
    trackEvent({ eventAction: 'LrpFiltersMobile', eventLabel: 'close' });
  };

  const pushNotification = (type: string, message: React.ReactElement) => setNotification({ type, message });

  const androidLinks = { androidPackage, androidScheme };
  const hasResults = isRequestSuccess && Boolean(totalResultCount);
  const isZSRP = isRequestSuccess && totalResultCount === 0;

  const searchRequestObject = useMemo(() => {
    return {
      ...searchRequest,
      attributes: attributeHierarchy,
      seller,
      seoFriendlyAttributes,
      seoFriendlyTextAttributes,
    };
  }, [searchRequest, attributeHierarchy, seller, seoFriendlyAttributes, seoFriendlyTextAttributes]);

  const searchCategoryName = getSearchCategoryName(categoriesById, searchCategory);

  const pageType = getPageType(hasResults);

  const altLocaleWithUrl = enrichLocaleAlt({
    localeAlt,
    alternativeLocales,
    attributeHierarchy,
    searchRequestObject,
  });

  const isSOIPage = !!seller;
  const canShowBannersRef = useRef<boolean>(true);

  useEffect(() => {
    canShowBannersRef.current = isSOIPage ? seller.showBanners : true;

    setShowSmartBanner(Boolean(seller));
  }, [isSOIPage, seller]);

  const canShowBanners = canShowBannersRef.current;

  const breadcrumbs = useMemo(
    () => formatBreadcrumbs(searchRequestObject, categoriesById, seller, totalResultCount),
    [searchRequestObject, categoriesById, seller, totalResultCount],
  );

  const { currentPage } = useMemo(
    () => paginationHelper(searchRequestObject.pagination, totalResultCount),
    [searchRequestObject.pagination, totalResultCount],
  );

  const channelData = useMemo(
    () =>
      getChannel({
        isZrp: isZSRP,
        isSrp: !!originalQuery,
        labsConfig,
        categories: searchRequestObject.categories,
        view: searchRequestObject.viewOptions.kind,
        currentPage,
        facets,
      }),
    [
      isZSRP,
      originalQuery,
      labsConfig,
      searchRequestObject.categories,
      searchRequestObject.viewOptions.kind,
      currentPage,
      facets,
    ],
  );

  const { attributesById, attributesByKey, attributeRanges, categories } = searchRequestObject;
  const hasAttributes = !attributesById.length && !attributesByKey.length && !attributeRanges.length;
  const smartLinkParams = useMemo(
    () =>
      buildSmartLinkParameters({
        categories: searchRequestObject.categories,
        query: searchRequestObject.searchQuery,
      }),
    [searchRequestObject.categories, searchRequestObject.searchQuery],
  );

  const iosLinks = { iosAppId, iosProtocol };
  const hasCategories = !!categories.l1Category || !!categories.l2Category;
  const isSOISearchWithNoSearchFilters = !hasCategories && !hasAttributes;
  const isSSOIWithZeroResults = isSSOI && isSOISearchWithNoSearchFilters && !hasResults;
  const currentSortBy = searchRequestObject.sortOptions?.sortBy;
  const customSortingApplied = !!currentSortBy && currentSortBy !== SORT_BY.OPTIMIZED;
  const showNDFCRankingsText = !isBot && !isZSRP && !customSortingApplied && !seller;
  const isBusinessSeller = seller?.active;
  const showBottomBlockListings =
    !seller && hasResults && !(isMobile && searchRequest?.viewOptions?.kind === VIEW_OPTIONS.GALLERY_VIEW);
  const shouldShowSrpDataDrivenContent =
    seoProperties?.dataDrivenContent?.isEnabledForTenant &&
    isTenOrMoreListings &&
    !isExcludedCategory(categories.l2Category?.id || categories.l1Category?.id || dominantCategory?.id);
  const shouldShowSellerDataDrivenContent = Boolean(
    !!seller &&
      isBusinessSeller &&
      seoProperties?.sellerDataDrivenContent?.isEnabledForTenant &&
      isTenOrMoreListings &&
      !isExcludedCategory(categories.l2Category?.id || categories.l1Category?.id || dominantCategory?.id),
  );

  const strippedMetaTitle = () => {
    const { pageTitleH1 } = metaTags;

    if (pageTitleH1.includes('<h1>')) {
      const h1Text = pageTitleH1.match(/<h1>(.*?)<\/h1>/)?.[1] || '';
      return stripHtml(h1Text);
    } else {
      return pageTitleH1;
    }
  };

  return (
    <>
      <Head>
        {showSmartBanner && <meta name="apple-itunes-app" content={iosSmartBanner(smartLinkParams, iosLinks)} />}
      </Head>
      <SeoMetaTags
        breadcrumbs={meta.breadcrumbs}
        product={meta.product}
        canonicalUrl={meta.canonicalUrl}
        title={metaTags?.metaTitle}
        description={metaTags.metaDescription}
        content={getRobotsContent(searchRequestObject, hasResults, isFetchResultsOnceAllCatEnabled)}
        altLocaleWithUrl={altLocaleWithUrl}
        hrefLangUrl={hrefLangUrl}
        localeAlt={localeAlt}
      />
      <Suspense fallback>
        {showSmartBanner && <AndroidSmartBanner parameters={smartLinkParams} smartLinkProps={androidLinks} />}
      </Suspense>
      {showExperimentalSmartBanner && isSmartBannerExperimentEnabled && <SmartBannerLarge />}
      <PageContainer>
        {seller && <SellerHeaderContainer sellerProfile={seller} pushNotification={pushNotification} />}
        {!isWide && canShowBanners && (
          <Banner position="top-dt" bannerForMobile={true} type="fluid" loading={topBannerLoadingState} />
        )}
        <Notification notification={notification} requestStatus={requestStatus} />
        <PageElement className="hz-Page-element hz-Page-element--full-width hz-Page-element--breadCrumbAndSaveSearch">
          <Breadcrumb
            breadcrumbs={breadcrumbs}
            correctedQuery={correctedQuery}
            originalQuery={originalQuery}
            searchRequestObject={searchRequestObject}
          />
          {!seller && (
            <SaveSearchContainer
              isDisabled={saveSearchButtonDisabled}
              setDisabled={setSaveSearchButtonDisabled}
              pushNotification={pushNotification}
              searchRequest={searchRequestObject}
              asPrimary
              showLabel
            />
          )}
        </PageElement>
        <PageElement className={`hz-Page-element--aside${isCarCategory ? ' with-expandable-layout' : ''}`}>
          <FilterSidebar
            facets={facets}
            isMobile={isMobile}
            isMobileFilterDialogOpen={isMobileFilterDialogOpen}
            onHideMobileFilterDialog={hideMobileFilterDialog}
            searchRequestObject={searchRequestObject}
            searchDistanceOptions={searchDistanceOptions}
            sharedDomainCookie={sharedDomainCookie}
            totalResults={totalResultCount}
            carsFilters={isCarCategory}
          />
          {!seller && <Banner position="skyscraper-dt" type="sticky-left" />}
          {!seller && hasResults && <Banner position="skyscraper-2-dt" type="sticky-left" />}
        </PageElement>
        <PageElement className="hz-Page-element--main">
          {!!isWide && canShowBanners && isZSRP && <Banner position="top-dt" bannerForMobile={false} type="fluid" />}
          {!isZSRP && (
            <>
              <FilterChips
                searchRequestObject={searchRequestObject}
                categoriesById={categoriesById}
                facets={facets}
                showMobileFilterDialog={showMobileFilterDialog}
              />
              <ViewControls
                sortOptions={sortOptions}
                searchRequestObject={searchRequestObject}
                setDisabled={setSaveSearchButtonDisabled}
                isDisabled={saveSearchButtonDisabled}
                pushNotification={pushNotification}
                isSoiPage={!!seller}
                isAllowSavedSearch={allowSavedSearch}
              />
            </>
          )}
          {!isCarCategory && isShippingCampaignEnabled && showShippingCampaignBanner && (
            <ShippingCampaignBanner buyerId={userDetails.id} />
          )}
          {isDAC7CommunicationEnabled && !isCarCategory && <Dac7PromoBanner />}
          {showNDFCRankingsText && <NDFCGenericRankingsText sharedDomainCookie={sharedDomainCookie} />}
          {!isZSRP && (
            <Listings
              topBlock={topBlock}
              listings={listings}
              searchRequestObject={searchRequestObject}
              correlationId={correlationId}
              showTopBanner={isWide && canShowBanners}
              showMidBanner={!isSOIPage}
              showAdsenseMidBanner={!isSOIPage}
              isSOIPage={isSOIPage}
              topBannerLoadingState={topBannerLoadingState}
              searchCategoryName={searchCategoryName}
              pageType={pageType}
              currentPage={currentPage}
              channel={channelData}
            />
          )}

          {showBottomBlockListings && <BottomBlockLazyListings searchRequestObject={searchRequestObject} />}
          {isZSRP && (
            <>
              {isSSOIWithZeroResults && <SSOIZeroResultsBox />}
              {!isSSOIWithZeroResults && (
                <ZRPSearchHelper searchRequestObject={searchRequestObject} hasErrors={hasErrors} />
              )}
            </>
          )}
          {!seller && isZSRP && (
            <ZrpSaveSearchHelper>
              <SaveSearchContainer
                isDisabled={saveSearchButtonDisabled}
                setDisabled={setSaveSearchButtonDisabled}
                pushNotification={pushNotification}
                searchRequest={searchRequestObject}
              />
            </ZrpSaveSearchHelper>
          )}
          {!seller && isRequestCompleted && !gclidQueryParam && (
            <AdsenseContainer
              query={searchRequestObject && searchRequestObject.searchQuery}
              searchCategoryName={searchCategoryName}
              pageType={pageType}
              currentPage={currentPage}
              channel={channelData}
            />
          )}
          {hasResults && (
            <Suspense fallback>
              <PaginationControls
                isNewPagination
                {...paginationHelper(searchRequestObject.pagination, totalResultCount)}
                searchRequestObject={searchRequestObject}
                allowSavedSearch={allowSavedSearch}
                showSaveSearchControl={false}
                showPageSizeControls={false}
                pushNotification={pushNotification}
                isDisabled={saveSearchButtonDisabled}
                setDisabled={setSaveSearchButtonDisabled}
              />
            </Suspense>
          )}
          {metaTags?.pageTitleH1 && (
            <Suspense fallback>
              <div className="hz-H1Title">
                <h1>{metaTags?.pageTitleH1 ? strippedMetaTitle() : ''}</h1>
              </div>
            </Suspense>
          )}
          {!seller && !!isWide && hasResults && (
            <Suspense fallback>
              <SuggestedSearches
                internalLinks={internalLinks}
                relevantInlinks={relevantInlinks}
                searchRequestObject={searchRequestObject}
              />
            </Suspense>
          )}
          {!seller && !!isWide && isRequestCompleted && <Banner position="bottom-dt-l" type="fluid" />}
        </PageElement>
        {!seller && !isWide && hasResults && (
          <>
            <Suspense fallback>
              <SuggestedSearches
                internalLinks={internalLinks}
                relevantInlinks={relevantInlinks}
                searchRequestObject={searchRequestObject}
              />
            </Suspense>
          </>
        )}
        {!seller && !isWide && isRequestCompleted && <Banner position="bottom-dt-l" type="fluid" />}
        {(shouldShowSrpDataDrivenContent || shouldShowSellerDataDrivenContent) && (
          <Suspense fallback>
            <DataDrivenContent
              originalQuery={originalQuery}
              facets={facets}
              categories={searchRequest.categories}
              totalResultCount={totalResultCount}
              priceSuggestions={priceSuggestions}
              dominantCategory={dominantCategory}
              dataTestId={shouldShowSellerDataDrivenContent ? 'SellerDataDrivenContent' : 'DataDrivenContent'}
              {...(shouldShowSellerDataDrivenContent && { seller })}
            />
          </Suspense>
        )}
        {shouldShowHubpageContent && (
          <Suspense fallback>
            <HubPages content={hubPage?.content as string} />
          </Suspense>
        )}
        {!seller && (
          <Suspense fallback>
            <FaqFooter searchRequestAndResponse={searchRequestAndResponse} seoFaqData={faqData} />
          </Suspense>
        )}
      </PageContainer>
    </>
  );
};

export default LrpContainer;
