import diacritics from 'diacritics';

export default function normalizeForUrl(input: string, replacement: string = '-'): string {
  return diacritics
    .remove(input)
    .toLowerCase()
    .replace(/[^0-9a-zA-Z]+/g, replacement) // remove non-alphanumeric characters
    .replace(new RegExp(`^${replacement}`), '') // remove leading 'replacement'
    .replace(new RegExp(`${replacement}$`), ''); // remove trailing 'replacement'
}
