import { changeToImageSizeAndRemoveExtension, ImageSize } from '@ecg-marktplaats/media-util-js-lib';

interface ImageSizeFormatterParams {
  imageUrlFromApi: string;
  customSize?: ImageSize;
}

const imageSizeFormatter = ({ imageUrlFromApi, customSize = ImageSize.Medium }: ImageSizeFormatterParams): string =>
  changeToImageSizeAndRemoveExtension(imageUrlFromApi, customSize, {
    allowPlaceholder: true,
    allowedSizes: [ImageSize.Medium],
  });

export default imageSizeFormatter;
